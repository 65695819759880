import "bootstrap";
// import "@splidejs/splide/dist/js/splide.min.js";
window.Swal = require('sweetalert2');
window.Splide = require('@splidejs/splide/dist/js/splide.min.js')
window.NiceSelect = require('nice-select2/src/js/nice-select2.js');
// console.log('loaded');


// Navbar Toggler Icon
let toggler = document.getElementById('navbar-toggler');
toggler.addEventListener('click', () => {
    console.log('clicked');
    toggler.classList.toggle('collapsed');
});

let preloader = document.getElementById('preloader');
if (preloader) {
  window.addEventListener('load', () => {
    preloader.remove()
  });
}
window.addEventListener('load', (event) => {
    // console.log('page is fully loaded');
});